import React, { useEffect, useRef, useState } from 'react'
import { RxCross1 } from "react-icons/rx";
import ServiceSelection from './ServiceSelection';
import { IoSearchSharp } from "react-icons/io5";
import FindAvailability from './FindAvailability';
import { toast } from 'react-toastify';
import appointmentInstance from '../../Instance/appointmentInstance';
import ErrorMsg from '../ErrorMsg';
import Spinner from '../Spinner';
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaExclamationCircle } from "react-icons/fa";

// react form hook
import { useForm } from "react-hook-form";
import { getHours, isSameDay, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { setCall } from '../../redux/slices/apiCall';
import { divideTimeSlots, getFirstDayOfMonth } from '../../utils/timeSlot';
import { durationListSystem } from '../../utils/durationListSystem';
import ServiceSelectionEdit from './ServiceSelectionEdit';

const CreateAppointment = ({ setModal }) => {
    const nameRef = useRef()
    const serviceRef = useRef()
    const [search, setSearch] = useState("")
    const [openSearch, setOpenSearch] = useState(false)
    const [dropView, setDropDropview] = useState(false)
    const [innerModal, setInnerModal] = useState(false)
    const [abortController, setAbortController] = useState(new AbortController());
    const [allCustomers, setAllCustomers] = useState()
    const [searchLoader, setSearchLoader] = useState(false)
    const [servicesList, setServicesList] = useState([])
    const [serviceLoader, setServiceLoader] = useState(false)
    const [serviceObj, setServiceObj] = useState({})
    const [servicesToShow, setServicesToShow] = useState([])
    const [teamMemberId, setTeamMemberId] = useState(null)
    const today = new Date()
    const todayDateTime = today.toISOString().slice(0, 16)
    const dispatch = useDispatch()
    const { call } = useSelector((state) => state?.apiCall);
    const [timeSlots, setTimeSlots] = useState([])
    const [currentMonth, setCurrentMonth] = React.useState(new Date());
    const [calenderData, setCalenderData] = useState([])
    const [loader, setLoader] = useState(false)
    const [smallLoader, setSmallLoader] = useState(false)
    const [duration, setDuration] = useState()
    const [stateUpdate, setStateUpdate] = useState(false)
    const [serviceDuration, setServiceDuration] = useState([])

    const {
        control,
        handleSubmit,
        register,
        trigger,
        getValues,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: {
            balance: null,
            number: null,
            startAt: null,
            customerNote: null,
            customerId: null,
        },
        mode: "onBlur",
        // resolver: yupResolver(appointmentSchema),
    });
    const formValues = getValues()


    // getting total duration minutes of services
    const gettingTotalDuration = () => {
        return servicesToShow.reduce((accumulator, item) => accumulator + parseInt(item?.durationMinutes || 0), 0)
    }



    const handleSelectName = (value) => {
        setValue("balance", ` $${parseFloat(value?.balance).toFixed(2)} `)
        setValue("number", value?.number)
        setValue("customerId", value?.customerId)
        setSearch(value?.name + " " + value?.familyName)
        setOpenSearch(false)
    }

    const generateUniqueId = (length = 18) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };


    const handleSave = async (value) => {
        const squareId = generateUniqueId();
        const teamMemberIds = servicesToShow.map(item => item?.teamMemberId);
        const uniqueTeamMemberIds = [...new Set(teamMemberIds)];
        try {
            // customer body
            const customerBody = {
                customerNote: value?.customerNote,
                startAt: value?.startAt,
                customerId: value?.customerId,
                status: "ACCEPTED",
                squareId: squareId,
                sellerNote: "",
                teamMemberId: teamMemberId === "any" ? servicesToShow[0]?.teamMemberId : teamMemberId,
                version: 1,
                locationId: "BAF0TQ869SHW9",
                feedback: 0,
                isImported: 1,
                duration: servicesToShow.reduce((accumulator, item) => accumulator + parseInt(item?.durationMinutes), 0),
            }
            const hour = value?.startAt ? getHours(parseISO(value?.startAt)) : null;
            if (!customerBody?.customerId) {
                toast.warning("Customer Name is required")
            } else if (!customerBody?.startAt) {
                toast.warning(`${teamMemberId ? "Please Select date & time is required" : "To Select Date & Time Please Add Services First"}`)
            }
            else if (hour < 9 || hour > 20) {
                toast.warning("Appointment time should be between 9:00 AM - 8:00 PM");
                return;
            }
            else if (!teamMemberId) {
                toast.warning("Please Select Staff from Find Availability")
            }
            else if (servicesToShow?.length === 0) {
                toast.warning("Service is required")
            } else if (!servicesToShow?.every((item) => item?.durationMinutes)) {
                toast.warning("Please enter the duration in service(s)")
            }
            else if (uniqueTeamMemberIds.length > 1) {
                toast.warning("All services must be assigned to the same staff member.");
                return;
            }
            else if (parseFloat(formValues?.balance?.split("$")[1]) < 0) {
                toast.warning("You must recharged your account balance in order to create appointment");
                return;
            }

            else {
                const res = await appointmentInstance.post("/V2/CreateAppointment", customerBody)
                // service body
                const servicesBody = servicesToShow?.map((item) => {
                    return {
                        appointmentId: res?.data?.requestId,
                        itemId: item?.itemId,
                        itemName: item?.itemName,
                        serviceVariationId: item?.serviceVariationId,
                        serviceVariationName: item?.serviceVariationName,
                        serviceVariationVersion: item?.serviceVariationVersion,
                        durationMinutes: parseInt(item?.durationMinutes),
                        teamMemberId: item?.teamMemberId,
                        amount: parseInt(item?.amount),
                        status: 1,
                    }
                })
                if (res?.data?.message === "Appointment already booked") {
                    toast.warning("Appointment already booked")
                }
                else if (res?.data?.message === "Appointment time should be between 9:00 AM - 8:00PM") {
                    toast.warning("Appointment time should be between 9:00 AM - 8:00PM")
                }
                else if (res?.data?.message === "Team member is not available") {
                    toast.warning("Team member is not available")
                }
                else if (res?.data?.message === "Appointment time must be in minutes of 10") {
                    toast.warning("Appointment time must be in minutes of 10")
                }
                // "Appointment time must be in minutes of 10"
                else if (res?.data?.status === "success") {
                    await appointmentInstance.post("V2/CreateAppointmentProducts", servicesBody)
                    toast.success("Successfully Appointment Created")
                    setModal("")
                    dispatch(setCall(!call))
                }
            }
        } catch (error) {
            toast.error("Please try again later")
        }

    }

    const gettingCustomerBySeach = async () => {
        try {
            setSearchLoader(true)
            abortController.abort();
            const newAbortController = new AbortController();
            setAbortController(newAbortController);
            const res = await appointmentInstance.get(`/GetCustomers?search=${search}`, {
                signal: newAbortController.signal
            })
            setAllCustomers(res?.data?.data)
            setSearchLoader(false)
        } catch (err) {
            if (err?.message !== "canceled") {
                setSearchLoader(false)
            }
        }
    }

    const gettingServices = async () => {
        try {
            setServiceLoader(true)
            const res = await appointmentInstance.get(`/GetServices?teamMemberId=${teamMemberId !== "any" ? teamMemberId : ""}`)
            setServicesList(res?.data?.data)
            setServiceLoader(false)
        } catch (err) {
            setServiceLoader(false)
            toast.error("Some error occured while getting services")
        }
    }


    const handleEnterKey = (e) => {
        if (e?.key === "Enter") {
            gettingCustomerBySeach()
            setOpenSearch(true)
        }
    }

    // handle open service modal
    const handleOpenService = (value) => {
        setDropDropview(false);
        setInnerModal("serviceSelection")
        setServiceObj(value)
    }

    // handle open service modal for edit
    const handleOpenServiceEdit = (value, index) => {
        const body = {
            ...value,
            index
        }
        setDropDropview(false);
        setInnerModal("serviceSelectionEdit")
        setServiceObj(body)
    }

    // handle remove service 
    const handleRemoveService = (index) => {
        try {
            const temp = [...servicesToShow]
            const filterOut = temp.filter((item, i) => i !== index)
            setServicesToShow(filterOut)
            toast.success("Service removed")
        } catch (err) {
            toast.error("Some error occured while removing")
        }
    }

    // getting calender data with teamMember Id without any
    const gettingCalenderInfo = async (smallLoad = false, date = null, month = null) => {
        try {

            if (gettingTotalDuration() > 0) {
                if (teamMemberId) {
                    if (smallLoad === true) {
                        setSmallLoader(true)
                    } else {
                        setLoader(true)
                    }
                    abortController.abort();
                    const newAbortController = new AbortController();
                    setAbortController(newAbortController);
                    const res = await appointmentInstance.get(`FindAvailability?teamMemberId=${teamMemberId}&date=${getFirstDayOfMonth(month ? month : currentMonth)}&duration=${gettingTotalDuration()}`, {
                        signal: newAbortController.signal
                    })
                    const data = res?.data;
                    if (res?.data?.message === "Team member not found") {
                        return
                    }
                    if (data) {
                        const dividedTimeSlots = divideTimeSlots(data, gettingTotalDuration());
                        setCalenderData(dividedTimeSlots)
                        const selectedDateObj = dividedTimeSlots?.find(item => isSameDay(parseISO(item.date), date ? date : formValues?.startAt));
                        if (selectedDateObj !== undefined) {
                            setTimeSlots(selectedDateObj?.timeSlots)
                        } else {
                            setTimeSlots([])
                        }
                    }
                    if (smallLoad === true) {
                        setSmallLoader(false)
                    } else {
                        setLoader(false)
                    }
                }
            }
        } catch (err) {
            if (err?.message === "canceled") {
                return
            }
            if (smallLoad === true) {
                setSmallLoader(false)
            } else {
                setLoader(false)
            }
            toast.error("Some error occured while getting calender info")
        }
    }


    // getting service duration dropdown list
    const gettingServiceDuration = async () => {
        try {
            const res = await appointmentInstance.get("/GetVariantsDuration")
            const data = res?.data?.data?.map((item) => {
                return { name: item }
            })
            setServiceDuration(data)
        } catch (error) {
            toast.error("Some error occured while getting duration")
        }
    }


    // handle search 
    const handleSearch = (value) => {
        // Use a regular expression to allow only a-z, A-Z, 0-9, and @
        // const filteredValue = value?.replace(/[^a-zA-Z0-9@]/g, '');
        setSearch(value);
        setOpenSearch(true);
    }

    const findMatchingTimeSlot = (dateTime, appointments) => {
        const dateString = dateTime.toISOString().split('T')[0];
        for (let appointment of appointments) {
            if (appointment.date.split('T')[0] === dateString) {
                for (let timeSlot of appointment.timeSlots) {
                    const from = new Date(timeSlot.from);
                    const to = new Date(timeSlot.to?.split("+")[0]);
                    if (dateTime >= from && dateTime <= to) {
                        return timeSlot;
                    }
                }
            }
        }
        return null;
    };


    // handle duration service 
    const handleDurationService = (index, value) => {
        try {
            // const temp = [...servicesToShow];
            // const currentDuration = temp[index].durationMinutes || 0;
            // const newDuration = parseInt(value);
            // if (formValues?.startAt) {

            //     const totalDurationExcludingCurrent = gettingTotalDuration() - currentDuration;
            //     const maxDuration = differenceBetweenStartEnd();

            //     if (totalDurationExcludingCurrent + newDuration <= maxDuration) {
            //         temp[index].durationMinutes = newDuration;
            //         setServicesToShow(temp);
            //         setStateUpdate(!stateUpdate);
            //     } else if (newDuration <= maxDuration) {
            //         temp[index].durationMinutes = "";
            //         setServicesToShow(temp);
            //         toast.warning("Please assign a new value that fits within the limit");
            //     } else {
            //         temp[index].durationMinutes = "";
            //         setServicesToShow(temp);
            //         toast.warning("Limit exceed");
            //     }
            // }
            // else {
            //     temp[index].durationMinutes = newDuration;
            //     setServicesToShow(temp);
            //     toast.warning("Please Select Date & Time");
            // }

            const temp = [...servicesToShow];
            const currentDuration = temp[index].durationMinutes || 0;
            const newDuration = parseInt(value);
            temp[index].durationMinutes = newDuration;
            setServicesToShow(temp);
            setStateUpdate(!stateUpdate);
        } catch (err) {
            toast.error("Some error occurred while adding duration");
        }
    }


    const handleBlur = async (fieldName) => {
        try {
            await trigger(fieldName);
        } catch (error) {
            console.error(error);
        }
    };

    // date & time handler
    const handleBlurDate = async (fieldName) => {
        try {
            if (!teamMemberId) {
                setValue("startAt", null)
                toast.warning("Please Select First Staff from Find Availability")
            } else {
                await trigger(fieldName);
                const { startAt } = getValues(); // get the current value of the field
                const date = new Date(startAt);
                if (startAt) {
                    var appointment = isAppointmentAvailable(date, calenderData); // assuming appointmentsArray is your array of objects
                }
                if (!appointment) {
                    setValue("startAt", formValues?.startAt)
                    toast.warning('Appointment found with the given date and time or Check Find Availability');
                    // setTimeSlots([])
                } else {
                    // Process the found appointment
                    // toast.success("Process the found appointment")
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    // getting total
    const gettingTotal = () => {
        const total = servicesToShow.reduce((accumulator, item) => accumulator + parseInt(item?.amount), 0);
        return parseFloat(total).toFixed(2)
    }



    // getting minutes difference total
    const getMinutesDifference = (from, to) => {
        const fromDate = new Date(from);
        const toDate = new Date(to.split("+")[0]);
        const diffInMs = toDate - fromDate;
        const diffInMinutes = diffInMs / 1000 / 60;
        return parseInt(Math.round(diffInMinutes));
    };



    // getting difference between input time and to time
    const differenceBetweenStartEnd = () => {
        const { startAt } = getValues(); // get the current value of the field
        let dateTime = new Date(startAt);
        if (isNaN(dateTime)) {
            console.error("Invalid Date");
            return null; // or return a specific value indicating an invalid date
        }
        let seconds = dateTime.getSeconds() + 1
        dateTime.setSeconds(seconds)
        const timeSlot = findMatchingTimeSlot(dateTime, calenderData);
        // setDuration(timeSlot)
        if (timeSlot) {
            const minutesDifference = getMinutesDifference(dateTime, timeSlot?.to);
            return minutesDifference
        }
    }


    // handle service dropdown
    const handleDropDown = () => {
        if (parseFloat(formValues?.balance?.split("$")[1]) < 0) return
        setDropDropview(!dropView)
    }


    const isAppointmentAvailable = (dateTime, appointments) => {
        const dateString = dateTime.toISOString().split('T')[0];
        for (let appointment of appointments) {
            if (appointment.date.split('T')[0] === dateString) {
                for (let timeSlot of appointment.timeSlots) {
                    const from = new Date(timeSlot.from);
                    const to = new Date(timeSlot.to?.split("+")[0]);
                    if (dateTime >= from && dateTime <= to) {
                        return appointment;
                    }
                }
            }
        }
        return null;
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (nameRef.current && !nameRef.current.contains(event.target)) {
                setOpenSearch(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [nameRef]);

    useEffect(() => {
        if (search?.length === 0) {
            setOpenSearch(false)
            setValue("customerId", null)
        } else {
            gettingCustomerBySeach()
        }
    }, [search])

    useEffect(() => {
        function handleClickOutside(event) {
            if (serviceRef.current && !serviceRef.current.contains(event.target)) {
                setDropDropview(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [serviceRef]);

    useEffect(() => {
        gettingServiceDuration()
    }, [])

    useEffect(() => {
        if (servicesToShow?.length === 0) {
            setTeamMemberId("")
        }
        if (teamMemberId !== "any") {
            gettingServices()
        }
    }, [teamMemberId, servicesToShow])

    useEffect(() => {
        if (gettingTotalDuration() > 0 && servicesToShow?.length > 0) {
            if (teamMemberId === "any") return
            gettingCalenderInfo()
        }
    }, [teamMemberId, servicesToShow, formValues?.startAt])

    useEffect(() => {
        gettingTotal()
        differenceBetweenStartEnd()
        gettingTotalDuration()
    }, [servicesToShow, formValues?.startAt])



    return (
        <>
            {innerModal === "findAvailability" && <FindAvailability gettingTotalDuration={gettingTotalDuration} setServicesToShow={setServicesToShow} isAppointmentAvailable={isAppointmentAvailable} setValue={setValue} setDuration={setDuration} duration={duration} smallLoader={smallLoader} setSmallLoader={setSmallLoader} loader={loader} setLoader={setLoader} calenderData={calenderData} setCalenderData={setCalenderData} currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} gettingCalenderInfo={gettingCalenderInfo} setTimeSlots={setTimeSlots} timeSlots={timeSlots} servicesToShow={servicesToShow} formValues={formValues} teamMemberId={teamMemberId} setTeamMemberId={setTeamMemberId} setInnerModal={setInnerModal} />}
            {innerModal === "serviceSelectionEdit" && <ServiceSelectionEdit setTeamMemberId={setTeamMemberId} teamMemberId={teamMemberId} servicesToShow={servicesToShow} setServicesToShow={setServicesToShow} serviceObj={serviceObj} setInnerModal={setInnerModal} />}
            {innerModal === "serviceSelection" && <ServiceSelection setTeamMemberId={setTeamMemberId} teamMemberId={teamMemberId} servicesToShow={servicesToShow} setServicesToShow={setServicesToShow} serviceObj={serviceObj} setInnerModal={setInnerModal} />}
            <div className='fixed top-0 left-0 w-full bg-[#4a4a4aa0] z-[9992] h-full drop-shadow-2xl  flex justify-center items-center'>
                <div className={`bg-white py-4 px-10 w-[850px]   overflow-auto max-h-[710px] rounded-md transition-all relative ease-in-out duration-300 transform`}>
                    <div className='sticky top-0'>
                        <p className='text-2xl font-bold text-center'>Create Appointment</p>
                        <RxCross1 onClick={() => { setModal("") }} className='absolute right-0 top-1.5 cursor-pointer text-2xl font-bold' />
                    </div>
                    <div className='flex my-5 justify-between items-center'>
                        <p className='text-[15px] font-semibold mt-4'>Client Information</p>
                        <button disabled={isSubmitting || parseFloat(formValues?.balance?.split("$")[1]) < 0} onClick={handleSubmit(handleSave)} className='bg-newLightBlue disabled:bg-gray-400 font-semibold text-white border-none outline-none rounded-md py-1 px-6 '> {isSubmitting ? <Spinner /> : "Save"} </button>
                    </div>
                    <div className='grid relative grid-cols-4 border border-1 mt-3 border-gray-300 border-b-newLightBlue  rounded-b-none'>
                        <div className='col-span-1 py-2 px-4 bg-[#CCE1FF] h-full'>
                            <p className='font-semibold'>Name</p>
                        </div>
                        <div className='col-span-3 py-2 px-4 relative'>
                            <input onClick={() => { setOpenSearch(true) }} onKeyDown={(e) => { handleEnterKey(e) }} onChange={(e) => { handleSearch(e?.target?.value) }} value={search} type="text" placeholder='Enter Name' className='w-full h-full border-none outline-none' />

                            {
                                (openSearch && search?.length > 0) &&
                                <div ref={nameRef} className={`absolute bg-white  z-20 flex overflow-y-auto gap-2 flex-col py-1 px-3 bottom-[-175px] left-[-2px] w-full h-[170px]`}>
                                    {
                                        searchLoader === true ?
                                            <Spinner />
                                            :
                                            allCustomers?.length > 0 ? allCustomers?.map((item) => {
                                                return (
                                                    <>
                                                        <div className='cursor-pointer' onClick={() => { handleSelectName(item) }}>
                                                            <p className='text-lg font-semibold'>{item?.name + " " + item?.familyName} {item?.membership == 2 && '|'} {item?.membership == 1 && '|'} {item?.membership === 2 ? "Plus Member" : item?.membership === 1 && "Plus Basic"}</p>
                                                            <p className='text-sm font-semibold'>{item?.number}, {item?.email}</p>
                                                        </div>
                                                    </>
                                                )
                                            })
                                                :
                                                <ErrorMsg msg={"No Customer Found"} />
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    <div className='grid gap-4  grid-cols-4 border border-1 border-t-0  border-gray-300  '>
                        <div className='col-span-2 flex justify-between  items-center'>
                            <div className='bg-[#F2F2F2] w-[58%] py-2 px-6'>
                                <p className='font-semibold text-sm'>Account <br /> Balance</p>
                            </div>
                            <div className='py-2 px-4'>
                                <input
                                    disabled
                                    control={control}
                                    {...register("balance")}
                                    onBlur={() => handleBlur("balance")}
                                    name="balance"
                                    type="text" placeholder='Account Balance' className='w-full placeholder:text-sm h-full border-none outline-none' />
                            </div>
                        </div>
                        <div className='col-span-2  flex justify-between  items-center'>
                            <div className='bg-[#F2F2F2] h-full flex items-center w-[58%] py-2 px-6'>
                                <p className='font-semibold'>Phone</p>
                            </div>
                            <div className='py-2 px-6'>
                                <input
                                    disabled
                                    control={control}
                                    {...register("number")}
                                    onBlur={() => handleBlur("number")}
                                    name="number"
                                    type="number" placeholder='Primary Phone Number' className='w-full placeholder:text-sm h-full border-none outline-none' />
                            </div>
                        </div>
                    </div>

                    {
                        parseFloat(formValues?.balance?.split("$")[1]) < 0 &&
                        <div className='flex gap-2 mt-2 text-red-700 items-center'>
                            <FaExclamationCircle />
                            <p className='text-sm'> You must recharged your account balance in order to create appointment</p>
                        </div>
                    }

                    <p className='mt-10 text-lg font-semibold'>Appointment Details</p>
                    <div className='flex justify-start  mt-3  border border-1   border-gray-300  '>
                        <div className='  flex justify-between  items-center'>
                            <div className='bg-[#F2F2F2] w-[210px]  py-2 px-4 '>
                                <p className='font-semibold'>Date & Time</p>
                            </div>
                            <div className='pl-4 relative w-full'>
                                <input
                                    min={todayDateTime}
                                    control={control}
                                    {...register("startAt")}
                                    disabled={!teamMemberId || parseFloat(formValues?.balance?.split("$")[1]) < 0}
                                    onBlur={() => handleBlurDate("startAt")}
                                    name="startAt"
                                    type="datetime-local" className='w-full h-full border-none outline-none' />
                                {!teamMemberId && <IoIosInformationCircleOutline onClick={() => { toast.warning("To Select Date & Time Please Add Services First") }} title='To Select Date & Time Please Add Services First' className='absolute transform transition-all ease-out hover:scale-125 cursor-pointer text-lg right-[-40px] top-[5px]' />}
                            </div>
                        </div>
                        <div className='flex justify-end w-full  items-center'>
                            {servicesToShow?.length > 0 &&
                                <div className='flex items-center gap-2  py-2 px-4'>
                                    <IoSearchSharp className='text-gray-500' />
                                    <p onClick={() => { setInnerModal("findAvailability") }} className='font-semibold cursor-pointer whitespace-nowrap text-newLightBlue'>  Find Availability</p>
                                </div>

                            }
                        </div>
                    </div>


                    <div className='border  gap-5 border-1 border-t-0   border-gray-300    flex justify-start  '>
                        <div className='w-[180px] bg-[#F2F2F2] py-3  px-4'>
                            <p className='font-bold text-[11px]'>Appointment Notes</p>
                        </div>
                        <div className='w-full'>
                            <input
                                disabled={parseFloat(formValues?.balance?.split("$")[1]) < 0}
                                control={control}
                                {...register("customerNote")}
                                onBlur={() => handleBlur("customerNote")}
                                name="customerNote"
                                type="text" placeholder='Add notes viewable by staff only (optional)' className='w-full  h-full border-none outline-none' />
                        </div>
                    </div>


                    {/* Header */}
                    <div className='grid gap-4 mt-5  grid-cols-6 border border-1 bg-[#F2F2F2]  border-gray-300 py-2 px-4 '>
                        <div className='col-span-2 flex justify-between  items-center'>
                            <div>
                                <p className='font-semibold'>Services</p>
                            </div>
                        </div>
                        <div className='col-span-2 flex justify-between  items-center'>
                            <div>
                                <p className='font-semibold'>Duration</p>
                            </div>
                        </div>

                        <div className='col-span-2 flex justify-between  items-center'>
                            <div className='w-full'>
                                <p className='font-semibold text-right '>Amount</p>
                            </div>
                        </div>
                    </div>
                    {/* Header End */}


                    {/* Data */}
                    <div className='w-full relative max-h-[140px] overflow-y-auto'>
                        {servicesToShow?.length > 0 && servicesToShow?.map((item, index) => {
                            const modifiedServiceVariationName = item?.serviceVariationName?.replace(/regular/i, '');
                            return (
                                <div className='grid gap-4 relative grid-cols-6 border border-1 border-t-0  border-gray-300 py-2 px-4 '>
                                    <div className='col-span-2 flex justify-between  items-center'>
                                        <div onClick={() => { handleOpenServiceEdit(item, index) }} className='flex cursor-pointer flex-col'>
                                            <p className='font-semibold text-newLightBlue'>{item?.itemName} + {modifiedServiceVariationName}</p>
                                            <p>{item?.staff}</p>
                                        </div>
                                    </div>
                                    <div className='col-span-2  flex justify-between  items-center'>
                                        <div className=' w-full'>
                                            <select value={item?.durationMinutes} onChange={(e) => { handleDurationService(index, e?.target?.value) }} className='w-full border-none outline-none'>
                                                <option selected disabled value="">Select Duration</option>
                                                {serviceDuration?.filter((res) => res?.name === item?.durationMinutes)?.map((item) => {
                                                    return (
                                                        <option disabled hidden value={item?.name}>{item?.name}m</option>
                                                    )
                                                })}
                                                {
                                                    durationListSystem?.filter((res) => res?.name !== item?.durationMinutes)?.map((item) => {
                                                        return (
                                                            <option value={item?.value}>{item?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {/* <button onClick={() => { setDurationDropView({ view: !durationDropView, index: index }) }} > {item?.durationMinutes ? `${item?.durationMinutes}M` : "Open Dropdown"}</button> */}
                                        </div>
                                    </div>

                                    <div className='col-span-2 flex justify-between  items-center'>
                                        <div className='w-full'>
                                            <p className='font-semibold text-left ml-4 '>${parseFloat(item?.amount ? item?.amount : 0).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <RxCross1 onClick={() => { handleRemoveService(index) }} className='absolute  bottom-[25px] right-[10px] text-gray-400 cursor-pointer' />
                                </div>
                            )
                        })}
                    </div>
                    <div className='relative '>
                        {
                            dropView &&
                            <div ref={serviceRef} className={`absolute bg-white border border-lightBlue z-20 flex overflow-y-auto gap-2 flex-col py-1 px-3 top-[-240px] left-[0px] w-[400px]  h-[280px]`}>
                                <p className='uppercase text-sm mt-1 font-semibold'>All Services</p>
                                {
                                    serviceLoader === true ?
                                        <Spinner /> :
                                        servicesList?.length === 0 ?
                                            <ErrorMsg msg="No Service Found" />
                                            :
                                            servicesList?.map((item) => {
                                                return (
                                                    <div className='cursor-pointer grid grid-cols-4 items-start' onClick={() => { handleOpenService(item) }}>
                                                        <div className=' col-span-3'>
                                                            <p className='text-sm font-semibold'>{item?.productName}</p>
                                                        </div>
                                                        <div className='text-right col-span-1'>
                                                            <p className='text-sm font-semibold'>{item?.productVariants?.length} Variations</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </div>
                        }
                    </div>
                    <div className='w-full border py-2 border-t-0 px-3 border-b-gray-300'>
                        <p onClick={() => { handleDropDown() }} className='mt-4 text-lightBlue w-[100px] font-semibold cursor-pointer'>Add Service</p>
                    </div>

                    <div className='grid gap-4  grid-cols-6  py-2 px-4 '>
                        <div className='col-span-2  flex justify-between  items-center'>
                            <div>
                                <p className='font-semibold'></p>
                            </div>
                        </div>
                        <div className='col-span-2 flex justify-between  items-center'>
                            <div>
                                <p className='font-semibold text-lg'>Total</p>
                            </div>
                        </div>

                        <div className='col-span-2 flex justify-between  items-center'>
                            <div className='w-full'>
                                <p className='font-semibold text-right text-lg'>${gettingTotal()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CreateAppointment

