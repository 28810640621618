import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ErrorMsg from '../ErrorMsg'
import Spinner from '../Spinner'
import appointmentInstance from '../../Instance/appointmentInstance'

const ServiceSelectionEdit = ({ setTeamMemberId, teamMemberId, setInnerModal, serviceObj, setServicesToShow, servicesToShow }) => {
    const [obj, setObj] = useState({})
    const [productVariants, setProductVariants] = useState([])
    const [loader, setLoader] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [serviceLoader, setServiceLoader] = useState(false)

    // block dropdown if services is greater than 0
    const blockDropdown = () => {
        return servicesToShow?.length > 0 ? true : false
    }

    const handleCheckBox = (value) => {
        try {
            console.log("value", value)
            setObj({
                ...obj,
                teamMemberId: obj?.teamMemberId,
                staff: serviceObj?.staff, variantId: value?.variantId, durationMinutes: value?.duration ? value?.duration : "10", serviceVariationName: value?.name, itemName: serviceObj?.productName, amount: value?.price, serviceVariationId: value?.variantId, serviceVariationVersion: value?.variantVersion, teamId: value?.teamMemberId
            })
        } catch (err) {
            setLoader(false)
            setBtnLoader(false)
        }
    }

    const handleSave = () => {
        try {
            if (!obj?.teamMemberId) {
                toast.warning("Staff is requried")
            } else if (!obj?.variantId) {
                toast.warning("Variant is requried")
            } else {
                const temp = [...servicesToShow]
                temp[serviceObj?.index].staff = obj?.staff
                temp[serviceObj?.index].itemId = serviceObj?.itemId
                temp[serviceObj?.index].serviceVariationId = obj?.serviceVariationId
                temp[serviceObj?.index].serviceVariationName = obj?.serviceVariationName
                temp[serviceObj?.index].serviceVariationVersion = obj?.serviceVariationVersion
                temp[serviceObj?.index].amount = obj?.amount
                temp[serviceObj?.index].durationMinutes = obj?.durationMinutes
                setServicesToShow(temp)
                setInnerModal("")
                toast.success("Succesfully Variation Updated")
                setTeamMemberId(obj?.teamMemberId)
            }
        } catch (err) {
            toast.error("Some error occured")
        }
    }

    // getting serivce variants
    const gettingServiceVariants = async () => {
        try {
            setServiceLoader(true)
            const res = await appointmentInstance?.get(`/GetProductVariants?productId=${serviceObj?.itemId}`)
            setProductVariants(res?.data?.data)
            setServiceLoader(false)
        } catch (error) {
            setServiceLoader(false)
            toast.error("Some error occured while getting Service Variants")
        }
    }

    useEffect(() => {
        setObj(prevObj => ({
            ...prevObj,
            staff: serviceObj?.staff,
            teamMemberId: teamMemberId,
            variantId: serviceObj?.serviceVariationId,
            teamId: serviceObj?.teamMemberId,
            serviceVariationName: serviceObj?.serviceVariationName,
            serviceVariationId: serviceObj?.serviceVariationId,
            serviceVariationVersion: serviceObj?.serviceVariationVersion,
            durationMinutes: serviceObj?.durationMinutes,
            amount: serviceObj?.amount,
            itemId: serviceObj?.itemId
        }));
        gettingServiceVariants()
    }, []);


    return (
        <div className='fixed top-0 left-0 w-full bg-[#4a4a4aa0] z-[99999] h-full drop-shadow-2xl  flex justify-center items-center'>
            <div className={`bg-white overflow-auto w-[540px]  rounded-md transition-all relative p-3 ease-in-out duration-300 transform`}>
                <div className="p-4">
                    <p className='text-lg font-semibold'>{serviceObj?.itemName}</p>
                    <div className='grid border p-2 mt-6 border-1 border-lightGray grid-cols-4 items-center'>
                        <div className='col-span-2 p-2 '>
                            <p className='font-semibold'>Staff</p>
                        </div>
                        <div className='col-span-2'>
                            <select value={obj?.teamMemberId || ""}
                                disabled={blockDropdown() || loader}
                                className='p-2 w-full outline-none text-newLightBlue'>
                                <option value={obj?.teamMemberId}>{serviceObj?.staff}</option>
                            </select>
                        </div>
                    </div>

                    <div className='grid max-h-[150px] overflow-y-auto border p-2 border-1 border-t-0 border-lightGray grid-cols-4 items-start'>
                        <div className='col-span-2 p-2 '>
                            <p className='font-semibold'>Variation</p>
                        </div>
                        <div className='col-span-2 flex flex-col gap-5'>

                            {
                                serviceLoader === true ?
                                    <Spinner />
                                    :
                                    productVariants?.length === 0 || !productVariants ?
                                        <ErrorMsg msg="No Variations Found" />
                                        :
                                        productVariants?.map((item) => {
                                            return (
                                                <div className='flex  items-center gap-3'>
                                                    <input className='cursor-pointer' onChange={() => { handleCheckBox(item) }}
                                                        // disabled={item?.teamMemberId !== obj?.teamMemberId ? true : false}
                                                        name="productVariant" checked={obj?.variantId === item?.variantId} type="radio" />
                                                    <p className='font-semibold text-sm'>{serviceObj?.itemName} + {item?.name}</p>
                                                </div>
                                            )
                                        })
                            }
                        </div>
                    </div>

                    <div className="flex gap-4 justify-between items-center w-full mt-6">
                        <button onClick={() => { setInnerModal("") }} className='bg-transparent text-lightBlue disabled:bg-gray-400 font-semibold  border border-1 border-lightBlue  outline-none rounded-md py-1 px-6 '>Cancel</button>
                        <button disabled={btnLoader || loader} onClick={() => { handleSave() }} className='bg-newLightBlue disabled:bg-gray-400 font-semibold text-white border-none outline-none rounded-md py-1 px-6 '> {btnLoader ? <Spinner /> : "Save"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSelectionEdit