import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { toast } from 'react-toastify'

// react form hook
import { useForm } from "react-hook-form";
import Spinner from '../Spinner';
import appointmentInstance from '../../Instance/appointmentInstance';
import moment from 'moment';
// import { yupResolver } from "@hookform/resolvers/yup";

const RescheduleAppointment = ({ setDuration, gettingTotalDuration, setValue, setInnerModal, setModal, objInfo, formValues, teamMemberId, callBack, search }) => {
    const {
        control,
        handleSubmit,
        register,
        trigger,
        setValue: mySetValue,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: {
            balance: null,
            customerNote: null,
        },
        mode: "onBlur",
        // resolver: yupResolver(profileValidation),
    });
    const currentDate = moment().format('MMMM/DD/YYYY')
    const rescheduleDate = moment(objInfo?.startAt?.split("+")[0]).format('MMMM/DD/YYYY')
    const [previousStartAt] = useState(objInfo?.startAt?.slice(0, 16))


    const handleReschedule = async (value) => {
        const body = {
            rescheduledDate: formValues?.startAt,
            squareId: objInfo?.squareId,
            status: objInfo?.status,
            sellerNote: value?.customerNote,
            customerId: formValues?.customerId,
            amount: parseFloat(value?.balance).toFixed(2),
            isRescheduledFee: currentDate === rescheduleDate ? true : false,
            startAt: objInfo?.startAt,
            teamMemberId: objInfo?.teamMemberId,
            duration: gettingTotalDuration(),
            createdAt: moment()?.format(),
        }
        try {
            if (!formValues?.customerId) {
                toast.warning("Customer name is required")
            }
            else if (currentDate === rescheduleDate && !value?.balance) {
                toast.warning("Fee Amount is required")
            }
            else if (!value?.customerNote) {
                toast.warning("Note is required")
            }
            else {
                const res = await appointmentInstance.put("/V2/UpdateAppointmentStatus", body)
                if (res?.data?.message === "Please add account Id") {
                    toast.warning("Please add account Id")
                    return
                }
                setInnerModal("")
                setModal("")
                toast.success("Rescheduled Successfully")
                callBack()
            }
        } catch (error) {
            toast.error("Some error occured")
        }
    }

    const handleBlur = async (fieldName) => {
        try {
            await trigger(fieldName);
        } catch (error) {
            console.error(error);
        }
    };


    // handle cross
    const handleCross = () => {
        setValue("startAt", previousStartAt)
        setInnerModal("")
        setDuration(null)
    }


    return (
        <div className='fixed top-0 left-0 w-full bg-[#4a4a4aa0] z-[9999] h-full drop-shadow-2xl  flex justify-center items-center'>
            <div className={`bg-white  w-[500px] overflow-auto  rounded-md transition-all relative ease-in-out duration-300 transform`}>
                <div className='relative flex p-4 justify-center border border-1 border-gray-200 items-center'>
                    <RxCross1 onClick={() => { handleCross() }} className='absolute rounded-sm left-4 top-4.5 cursor-pointer text-3xl  text-black p-2 border border-1 border-gray-400 font-bold' />
                    <p className='text-2xl font-bold'>Reschedule Appointment</p>
                </div>
                <div className=' px-5 py-4'>
                    <div className='bg-[#F2F2F2] p-4'>
                        <p className='font-semibold text-sm text-center'>Confirm Appointment with No-Show Protection</p>
                        <p className='text-sm my-3 font-normal text-center'>You may choose to charge the customer if marked as no-show or cancellation within 1 day of the appointment.</p>
                    </div>
                    <p className='text-[12px] my-1 font-normal'>Are you sure you want to reschedule your appointment with {objInfo?.customer?.name} {objInfo?.customer?.familyName} from {moment(formValues?.startAt?.split("+")[0])?.format("MMM D, YYYY")} at {moment(formValues?.startAt?.split("+")[0])?.format("h:mm a")}?</p>
                    <p className='text-sm mt-7 my-4 font-semibold'>Add Account Balance</p>
                    <div className='grid border  border-1 border-lightGray grid-cols-4 items-center'>
                        <div className='col-span-2 bg-[#F2F2F2] pl-8 p-3'>
                            <p className='font-semibold text-sm'>Fee Amount</p>
                        </div>
                        <div className='col-span-2 '>
                            <input
                                disabled={rescheduleDate === currentDate ? false : true}
                                control={control}
                                {...register("balance")}
                                onBlur={() => handleBlur("balance")}
                                name="balance"
                                type="number" placeholder='Enter Amount' className='h-full border-none outline-none w-full p-3' />
                        </div>
                    </div>

                    <div className='grid border   border-1 border-lightGray grid-cols-4 items-center'>
                        <div className='col-span-2 bg-[#F2F2F2] pl-8 p-3'>
                            <p className='font-semibold text-sm'>Notes</p>
                        </div>
                        <div className='col-span-2 '>
                            <input
                                control={control}
                                {...register("customerNote")}
                                onBlur={() => handleBlur("customerNote")}
                                name="customerNote"
                                type="text" placeholder='Required' className='h-full border-none outline-none w-full p-3' />
                        </div>
                    </div>

                    <p className='text-[11px] my-3'>This message will appear at the top of the email notification and on the client's appointment management page.</p>

                    <div className='w-full flex justify-between mt-6 items-center'>
                        <button onClick={() => { handleCross() }} className='rounded-sm border border-1 border-newLightBlue py-2 px-3 text-newLightBlue text-sm font-semibold'>Return to Editing</button>
                        <button disabled={isSubmitting} onClick={handleSubmit(handleReschedule)} className='rounded-sm border-none outline-none py-2 px-3 text-white bg-newLightBlue text-sm font-semibold'> {isSubmitting ? <Spinner /> : 'Reschedule Appointment'} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RescheduleAppointment